import React, { Component } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from "../_config";
import Axios from "axios";
import { authHeader } from "../_helpers/auth-header";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Link } from "react-router-dom";
export default class ParentDropDown extends Component {

    state = {
        dataset: null,
        bluePrint: null,
        fetching: true
    }
    loadViaId = null;
    componentDidMount() {
        let { entityAPI, loadVia, loadViaId } = this.props.bluePrint;
        this.setState({ bluePrint: this.props.bluePrint })

        if (!loadVia) {
            const requestOptions = {
                headers: authHeader()
            };
            if (this.props.bluePrint.entityFilter) {
                Axios.post(`${config.baseUrl}/api/user/${entityAPI}/filter`, { query: this.props.bluePrint.entityFilter }, requestOptions)
                    .then(res => res.data)
                    .then(handleSuccess)
                    .catch(handleError);
            }
            else {
                Axios.get(`${config.baseUrl}/api/user/${entityAPI}`, requestOptions)
                    .then(res => res.data)
                    .then(handleSuccess)
                    .catch(handleError);
            }
            let that = this;
            function handleSuccess(dataset) {
                that.setState({ dataset, fetching: false })
            }
            function handleError(err) {
                toast.error(`Unable to fetch ${entityAPI}`)
                that.setState({ dataset: -1, fetching: false })
            }
        } else if (loadVia && loadViaId) {

            this.loadByParent(this.props.bluePrint)
        } else {
            this.setState({ fetching: false })
        }
    }
    componentDidUpdate(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render

        let { loadVia, loadViaId } = nextProps.bluePrint;

        if ((loadViaId && loadViaId !== this.loadViaId && loadVia !== undefined)) {
            this.loadByParent(nextProps.bluePrint, true)
        }

    }
    loadByParent = (bluePrint, setNull = false) => {
        let { entityAPI, loadVia, loadViaId } = bluePrint;
        this.setState({ fetching: true })
        this.loadViaId = loadViaId;
        const requestOptions = {
            headers: authHeader()
        };
        Axios.post(`${config.baseUrl}/api/user/${entityAPI}/filter`, { query: { [loadVia]: loadViaId } }, requestOptions).then(dataset => {
            if (setNull) this.handleChange({ target: { value: null } });
            this.setState({ dataset: dataset.data, bluePrint: bluePrint, fetching: false })

        }).catch(err => {
            toast.error(`Unable to fetch ${entityAPI}`)
            this.setState({ dataset: -1, bluePrint: bluePrint, fetching: false })
        });
    }
    handleChange({ target }) {
        const { value } = target;
        this.props.onChange(value);
    }

    render() {
        let { label, errors, disabled, placeholder, displayfield, entityAPI, icon, iconColor, createNew } = this.props.bluePrint;
        let { value, name, fieldClass } = this.props;

        let { dataset, fetching } = this.state;

        return fetching ?
            <div className="primary-text mt-20">Fething {entityAPI}..</div>
            :
            <div className={`pdropdown-container ${createNew ? 'createnew' : ''}`}>
                {((dataset && dataset.length) > 0 ?
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel>{label}</InputLabel>
                        <Select
                            value={value}
                            disabled={disabled}
                            onChange={e => this.handleChange(e)}
                        >
                            <MenuItem value="">
                                <em>{placeholder}</em>
                            </MenuItem>

                            {dataset.map((d, l) => <MenuItem value={d._id ? d._id : d[displayfield]} key={l}>{d[displayfield]}</MenuItem>)}

                        </Select>
                    </FormControl> :
                    <div className="primary-text mt-20">No {entityAPI} found..</div>)}

                {createNew && <Link to={`/account/${name}/manage?redirect=${encodeURI('adjuster/manage')}`} onClick={() => setTimeout(() => window.location.reload(), 300)} className="row center">
                    <i className="fa fa-add"></i>
                </Link>
                }
            </div>
    }
}