import React from 'react';
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import Home from './pages/Anonymous/Home';
import Layout from './pages/Anonymous/Layout';
import { AnonymousRoute } from './_middlewares/_routeMiddleware/AnonymousRoute';
import Signup from './pages/Anonymous/Signup';
import EmailVerification from './pages/Anonymous/EmailVerification';
import Login from './pages/Anonymous/Login';
import ForgotPassword from './pages/Anonymous/ForgotPassword';
import { AuthenticatedRoute } from './_middlewares/_routeMiddleware/AuthenticatedRoute';
import Ratings from './pages/Authenticated/Ratings';
import AuthenticatedLayout from './pages/Authenticated/AuthenticatedLayout';
import Profile from './pages/Authenticated/Profile';
import { DataTable, Manage } from './components/CRUD';
import Search from './pages/Anonymous/Search';
import Adjuster from './pages/Anonymous/Adjuster';
import Page from './pages/Anonymous/Page';
import Company from './pages/Anonymous/Company';

const Router = () => {
    return (
        <Routes>
            <Route path='/' element={<Layout />}>
                <Route index element={<Home />} />
                <Route path='search' element={<Search />} />
                <Route path='adjuster/:id' element={<Adjuster />} />
                <Route path='company/:id' element={<Company />} />
                <Route path='/p/:slug' element={<Page />} />

                <Route path='auth' element={<AnonymousRoute><Outlet /></AnonymousRoute>}>
                    <Route path="" element={<Navigate to="login" replace={true} />} />
                    <Route path='signup' element={<Signup />} />
                    <Route path='login' element={<Login />} />
                    <Route path='email/:type/:email' element={<EmailVerification />} />
                    <Route path='forgot-password' element={<ForgotPassword />} />
                    <Route path="*" element={<Navigate to="login" replace={true} />} />
                </Route>

                <Route path='account' element={<AuthenticatedRoute><AuthenticatedLayout /></AuthenticatedRoute>}>
                    <Route path="" element={<Navigate to="profile" replace={true} />} />
                    <Route path='profile' element={<Profile />} />
                    <Route path='ratings' element={<Ratings />} />
                    <Route path='adjusters' element={<DataTable />} />
                    <Route path='adjuster/manage' element={<Manage />} />
                    <Route path='adjuster/manage/:id' element={<Manage />} />
                    <Route path='company/manage' element={<Manage />} />
                    <Route path="*" element={<Navigate to="profile" replace={true} />} />
                </Route>

                <Route path="*" element={<Navigate to="/" replace={true} />} />
            </Route>
        </Routes>
    );
};

export default Router;