import React, { Fragment, useEffect, useState } from 'react';
import './style.css';
import { connect } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { apiService } from '../../../_services/api.service';
import { APILoader } from '../../../components/APILoader';
import getColor from '../../../_helpers/getColor';
import { toast } from 'react-toastify';


const Search = ({ infosetting, user, loggedIn }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchQuery, setSearchQuery] = useState("");
    const [searchBarQuery, setSearchBarQuery] = useState("");
    const [searchMode, setSearchMode] = useState(1);

    const [adjusters, setAdjusters] = useState(null);
    const [page, setPage] = useState(1);
    const [pageEnded, setPageEnded] = useState(false);

    const [states, setStates] = useState([]);
    const [selectedState, setSelectedState] = useState(null);
    const [companies, setCompanies] = useState([]);
    const [company, setCompany] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        if (infosetting) {
            document.title = `Search Adjuster | ${infosetting.title}`;
        }
    }, [infosetting]);

    useEffect(() => {
        let q = searchParams.get("q");
        let state = searchParams.get("state");
        let company = searchParams.get("company");
        if (q === "" && !(company || state)) {
            return navigate("/");
        }

        fetchStates();
        if (state && company) {
            setSelectedState(state);
            setSearchMode(2);
        }

        setSearchQuery(q);
        setSearchBarQuery(q);
        doSearch(state && company ? { state, company } : q);
    }, [searchParams]);


    const doSearch = (q, mPage = 1) => {
        if (mPage == 1) {
            setAdjusters(null);
            setPageEnded(false);
        }

        setPage(mPage);
        let promise = null;
        if (q.state && q.company) {
            promise = apiService.filter("adjusters", { query: q, sortQuery: { first_name: 1 } }, { page: mPage })
        } else {
            promise = apiService.searchWithPagination("adjusters", mPage, q, 'first_name', 'asc')

        }

        promise.then(res => {
            setPageEnded(res.data.length < res.per_page);
            if (mPage === 1) {
                setAdjusters(res);
                return;
            }

            let nAdjusters = JSON.parse(JSON.stringify(adjusters));
            nAdjusters.data = nAdjusters.data.concat(res.data);
            nAdjusters["current_page"] = res.current_page;
            nAdjusters["total"] = nAdjusters.total + res.total;
            setAdjusters(nAdjusters);
        });

    }

    useEffect(() => {
        if (selectedState) {
            setCompanies([]);

            let sCompany = searchParams.get("company");

            if (company) {
                setCompany(null);
            }

            if (!company && sCompany) {
                setCompany(sCompany)
            }
            apiService.filter('companies', { query: { state: selectedState } })
                .then(setCompanies)
        }
    }, [selectedState]);

    const fetchStates = () => {
        apiService.getAll('states')
            .then(setStates)
    }

    const onSearchSubmit = (e) => {
        e.preventDefault();

        if (searchBarQuery === "") {
            return toast.error("Please enter adjuster name!");
        }
        navigate(`/search?q=${encodeURI(searchBarQuery)}`)

    }

    const onSearchSelectSubmit = (e) => {
        e.preventDefault();

        if (!(selectedState && company)) {
            return toast.error("Please select state and company!");
        }

        return navigate(`/search?state=${selectedState}&company=${company}`)
    }

    const handleAdjusterClick = (e, id) => {
        if (e.target.id === `adjuster-item-${id}`) {
            navigateToAdjuster(id);
        }
    }

    const navigateToAdjuster = (id) => {
        navigate(`/adjuster/${id}`)
    }

    const navigateToCompany = (id) => {
        navigate(`/company/${id}`)
    }


    return (
        <div className='height100 padder'>
            {adjusters ?
                <Fragment>
                    <form className={`hsb-row-container ${searchMode === 1 ? '' : 'dropdowns'}`} onSubmit={searchMode == 1 ? onSearchSubmit : onSearchSelectSubmit}>
                        {searchMode === 1 ? <Fragment>
                            <div className='hsb-outer-container search-bar-outer'>
                                <div className='hsb-container search-bar'>
                                    <i class="fa-solid fa-user-tag"></i>
                                    <input name='search' placeholder='Adjuster or Company Name' value={searchBarQuery} onChange={({ target }) => { setSearchBarQuery(target.value); }} />
                                </div>
                                <button className='btn btn-primary'>
                                    Search
                                </button>
                            </div>

                            <p className='search-banner-mode' onClick={() => setSearchMode(2)}>I want to find adjuster by state and company</p>
                        </Fragment> :
                            <Fragment>
                                <div className='hsb-container mt-20'>
                                    <i class="fa-solid fa-globe"></i>
                                    <select name='state' value={selectedState} onChange={(e) => setSelectedState(e.target.value)}>
                                        <option>Select State</option>
                                        {states.map((s, i) => <option value={s._id}>{s.name}</option>)}
                                    </select>
                                </div>

                                <div className='hsb-container mt-20'>
                                    <i class="fa-solid fa-building"></i>
                                    <select name='state' value={company} onChange={(e) => setCompany(e.target.value)}>
                                        <option>Select Company</option>
                                        {companies.map((s, i) => <option key={i} value={s._id}>{s.name}</option>)}

                                    </select>
                                </div>
                                <button className='btn btn-primary'>
                                    Search
                                </button>

                                <p className='search-banner-mode' onClick={() => setSearchMode(1)}>I want to find adjuster with name</p>

                            </Fragment>}

                    </form>


                    {adjusters.total > 0 ?
                        <div className='search-container'>
                            <h3 className='a-search-result'>
                                {adjusters.total} adjuster{adjusters.total > 1 ? 's' : ''} {searchQuery && searchQuery.length > 0 && `with "${searchQuery}"`}
                                <br />
                                <br />
                                <div className='red-text'>
                                    Can't find the adjuster you are looking for? <Link className='secondary-text' to="/account/adjuster/manage">Click here to create one</Link>
                                </div>
                            </h3>
                            <div className='adjusters-container'>
                                {adjusters.data.map((adjuster, index) => <div className='adjuster-item row' key={index}
                                    // to={`/adjuster/${adjuster._id}`}
                                    onClick={(e) => handleAdjusterClick(e, adjuster._id)}
                                    id={`adjuster-item-${adjuster._id}`}
                                >
                                    <div className='a-rating-container column' onClick={() => navigateToAdjuster(adjuster._id)}>
                                        <span className='a-rating-heading'>Rating</span>
                                        <div className='a-rating-box row center' style={{ backgroundColor: getColor(adjuster.rating) }}>
                                            {adjuster.rating === 0 ?
                                                <span className='a-rating'>NR</span>
                                                :
                                                <span className='a-rating'>
                                                    {adjuster.rating}{adjuster.rating.toString().length === 1 ? '.0' : ''}
                                                </span>
                                            }
                                        </div>
                                    </div>

                                    <div className='a-name-container column'>
                                        <span className='a-name' onClick={() => navigateToAdjuster(adjuster._id)}>{adjuster.first_name} {adjuster.last_name} </span>
                                        <span className='a-company-name' onClick={() => navigateToCompany(adjuster.company)}>{adjuster.companyObj.name}</span>
                                    </div>

                                    <div className='a-state-container row' onClick={() => navigateToAdjuster(adjuster._id)}>
                                        <span className='a-state-name'>{adjuster.stateObj.name}, US</span>
                                    </div>
                                </div>)}

                                {!pageEnded && <div className='column center mt-20'>
                                    <button className='btn btn-primary mt-10' onClick={() => doSearch(searchQuery, page + 1)}>
                                        Show More
                                    </button>
                                </div>}

                            </div>
                        </div>
                        :
                        <Fragment>
                            {/* <br /> */}
                            <div className='red-text'>
                                Can't find the adjuster you are looking for? <Link className='secondary-text' to="/account/adjuster/manage">Click here to create one</Link>
                            </div>
                            <h3 className='mt-20'>No adjuster {searchQuery && searchQuery.length > 0 ? `with "${searchQuery}" in its name` : `Found`}.</h3>

                            <p>Use the search bar above and check the spelling or try an alternate spelling.</p>

                        </Fragment>
                    }
                </Fragment> :
                //Loader
                <div className='height60 row center'>
                    <APILoader />
                </div>
            }


        </div >
    );
};


function mapStateToProps(state) {
    return { ...state.authentication, ...state };
}

export default connect(mapStateToProps)(Search);