import React, { Fragment, useEffect, useState } from 'react';
import './style.css';
import { connect } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { apiService } from '../../../_services/api.service';
import { APILoader } from '../../../components/APILoader';
import JoinRMPFamily from '../../../components/JoinRMPFamily';
import RatingSelector from '../../../components/RatingSelector';
import { Input, TextareaAutosize } from '@mui/material';
import { toast } from 'react-toastify';
import getColor from '../../../_helpers/getColor';
import getImageURL from '../../../_helpers/getImageURL';

const Company = ({ infosetting, user, loggedIn }) => {
    const params = useParams();
    const navigate = useNavigate();
    const [company, setAdjuster] = useState(null);
    const [ratings, setRatings] = useState(null);
    const [ratingValue, setRatingValue] = useState(4);
    const [reviewText, setReviewText] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [userRating, setUserRating] = useState(null);
    const [userRatingChecked, setUserRatingChecked] = useState(false);
    const [currentIp, setCurrentIp] = useState(null);
    const [replyIndex, setReplyIndex] = useState(-1);
    const [replyText, setReplyText] = useState("");

    useEffect(() => {
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => {
                setCurrentIp(data.ip);
            }).catch(error => {
                console.log('Error:', error);
            });
    }, []);

    useEffect(() => {
        if (infosetting && company) {
            document.title = `${company.name} | ${infosetting.title}`;
        }
    }, [company, infosetting]);


    useEffect(() => {
        if (params.id) {
            setUserRatingChecked(false);
            apiService.getSingle("companies", params.id)
                .then(setAdjuster)
                .catch(e => {
                    navigate('/');
                });
        }
    }, [params]);

    useEffect(() => {
        if (company) {
            apiService.filter('company-ratings', { query: { company: company._id } })
                .then(setRatings);
        }
    }, [company]);

    useEffect(() => {
        setReplyText("");
    }, [replyIndex]);

    useEffect(() => {
        if (ratings && company && currentIp && user) {
            let aRatingIndex = ratings.findIndex(r => r.ipAddress === currentIp);
            setUserRating(aRatingIndex > -1 ? ratings[aRatingIndex] : null);
            setUserRatingChecked(true);
        }
    }, [ratings, company, currentIp, user]);

    const onReviewSubmit = (e) => {
        e.preventDefault();

        if (currentIp) {
            setLoading(true);
            apiService.add('company-ratings', {
                rating: ratingValue,
                review: reviewText,
                user: user._id,
                company: company._id,
                ipAddress: currentIp
            })
                .then(g => {
                    window.location.reload();
                })
                .catch(e => {
                    window.alert(e);
                    toast.error(`An internal error occurred!`);
                    setLoading(false);
                })
        }
    }

    const onReplySubmit = async (e, index) => {
        e.preventDefault();
        if (replyText.trim().length === 0) {
            return toast.error("Please enter your reply then submit!");
        }

        let ratingsArr = JSON.parse(JSON.stringify(ratings));
        let rating = ratingsArr[index];
        rating["replies"] = rating["replies"] ? rating["replies"] : [];
        rating.replies.push({
            from: {
                _id: user._id,
                name: user.name,
                profileImage: user.profileImage
            },
            text: replyText,
            dateTime: new Date().getTime()
        });
        ratingsArr[index] = rating;
        setRatings(ratingsArr);
        setReplyIndex(-1);

        await apiService.update('company-ratings', rating, rating._id);
    }

    const onDeleteReply = async (index, rIndex) => {
        if (window.confirm("Are you sure you want to delete your reply?")) {
            let ratingsArr = JSON.parse(JSON.stringify(ratings));
            let rating = ratingsArr[index];
            rating.replies.splice(rIndex, 1);
            ratingsArr[index] = rating;
            setRatings(ratingsArr);
            await apiService.update('company-ratings', rating, rating._id);
        }
    }

    const onDeleteSubReply = async (index, rIndex) => {
        if (window.confirm("Are you sure you want to delete your reply?")) {
            let ratingsArr = JSON.parse(JSON.stringify(ratings));
            let rating = ratingsArr[index];
            rating.replies[rIndex]["response"] = null;
            rating.replies[rIndex]["responseDateTime"] = null;
            ratingsArr[index] = rating;
            setRatings(ratingsArr);
            await apiService.update('company-ratings', rating, rating._id);
        }
    }

    const onSubReplySubmit = async (e, index, rIndex, text) => {
        e.preventDefault();
        let ratingsArr = JSON.parse(JSON.stringify(ratings));
        let rating = ratingsArr[index];
        rating.replies[rIndex]["response"] = text;
        rating.replies[rIndex]["responseDateTime"] = new Date().getTime();

        ratingsArr[index] = rating;
        setRatings(ratingsArr);
        await apiService.update('company-ratings', rating, rating._id);
    }
    const ReplyContainer = ({ ratingUser, reply, index, rIndex, isMyReply }) => {
        const [replyActive, setreplyActive] = useState(false);
        const [subReply, setSubReply] = useState("");

        useEffect(() => {
            setSubReply("");
        }, [replyActive]);

        return <Fragment>
            <div className='adjuster-item row p-rating-item' key={rIndex}>
                <div className='a-rating-container column'>
                    <img src={getImageURL(reply.from.profileImage)} className='mt-10 a-reply-profile-image' />
                </div>
                <div className='a-name-container column p-rating-item-name'>
                    <span className='a-name'>{reply.from.name}</span>
                    <span className='ap-review-text'>{reply.text}</span>
                </div>
                <div className='a-state-container a-state-container-company column'>
                    <span className='a-state-name'>
                        {user && reply.from._id === user._id && <i className='fa-solid fa-trash text-danger cursor-pointer a-reply-trash' onClick={() => onDeleteReply(index, rIndex)}></i>}
                        <span className='ml-5'>{new Date(reply.dateTime).toLocaleDateString()}</span>
                    </span>
                    {isMyReply && !replyActive && !reply.response && reply.from._id !== ratingUser._id ? <button className='btn btn-primary btn-sm btn-response btn-reply-mobile' onClick={() => setreplyActive(true)}>Reply</button> : null}
                </div>
            </div>
            {reply.response && <div className='a-response-container column'>
                <div className='a-response-content'>
                    <div className='adjuster-item row p-rating-item'>
                        <div className='a-rating-container column'>
                            <img src={getImageURL(ratingUser.profileImage)} className='mt-10 a-reply-profile-image' />
                        </div>
                        <div className='a-name-container column p-rating-item-name'>
                            <span className='a-name'>{ratingUser.name}</span>
                            <span className='ap-review-text'>{reply.response}</span>
                        </div>
                        <div className='a-state-container a-state-container-company column'>
                            <span className='a-state-name'>
                                {isMyReply ? <i className='fa-solid fa-trash text-danger cursor-pointer a-reply-trash' onClick={() => onDeleteSubReply(index, rIndex)}></i> : null}
                                <span className='ml-5'>{new Date(reply.dateTime).toLocaleDateString()}</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>}


            {replyActive && <div className='a-response-container column'>
                <div className='a-response-content'>
                    <form className='a-reply-input-container' onSubmit={(e) => onSubReplySubmit(e, index, rIndex, subReply)}>
                        <Input placeholder='Add a reply...' className='a-reply-input' name='reply' value={subReply} onChange={(e) => setSubReply(e.target.value)} />
                        <div className='a-reply-input-button row mt-20 mb-20'>
                            <button type="button" className='btn btn-white btn-sm btn-response' onClick={() => setreplyActive(false)}>Cancel</button>
                            <button type='submit' className='btn btn-primary btn-sm btn-response ml-10' >Reply</button>
                        </div>
                    </form>
                </div>
            </div>}
        </Fragment>
    }

    return (
        <div className='height100 padder' >
            {company ? <div className='ap-container'>
                <div className='ap-detail-content'>
                    <div className='ap-detail-item column'>
                        <div className='ap-rating-display'>
                            <span className='ap-rating-v'>{company.rating}</span>
                            <span className='ap-rating-total'>/ 5</span>
                        </div>
                        {ratings && (ratings.length > 0 ?
                            <span className='ap-rating-caption'>Overall Quality Based on {ratings.length} ratings</span>
                            :
                            <span className='ap-rating-caption'>No Rating Yet!</span>)
                        }

                        <span className='ap-company-name'>{company.name}</span>
                        <span className='ap-rating-caption'>in
                            <Link to={`/search?q=${encodeURI(company.stateObj.name)}`}>{company.stateObj.name}</Link>
                        </span>

                        {company.additionalInfo && <div className='mt-10'>
                            <h3 className=''>Additional Information</h3>
                            <div dangerouslySetInnerHTML={{ __html: company.additionalInfo }} className='ap-additional-info-container'>
                            </div>
                        </div>
                        }
                    </div>
                    <div className='ap-rating-form'>
                        <div className='padder'>
                            <div className='crud-container'>
                                {loggedIn ?
                                    userRatingChecked ?
                                        userRating ? <div className='ap-rating-form-content column center ap-rating-done'>
                                            <i class="fa-solid fa-circle-check secondary-text"></i>
                                            <h3>You Rated this Company</h3>
                                            <RatingSelector value={userRating.rating} />
                                            <p className='ap-rating-caption text-center mt-20'>Company already rated by you.<br />Thank you for your feedback and review.</p>
                                        </div>
                                            : <div className='ap-rating-form-content'>
                                                <h3>Rate this Company</h3>
                                                <RatingSelector value={ratingValue} onChange={setRatingValue} />
                                                <hr className='hr2 mt-20'></hr>
                                                <form className='ap-rating-review-container' onSubmit={onReviewSubmit}>
                                                    <p className='primary-text mt-20'>Write a Review *</p>
                                                    <span className='ap-rating-caption'>Discuss the insurance company's capabilities, including their assessment process and ability to clearly communicate claim details.</span>
                                                    <div className='ap-rating-guide-container mt-20'>
                                                        <div className='row'>
                                                            <i class="fa-solid fa-triangle-exclamation"></i>
                                                            <span className='primary-text ml-10'>Guidelines</span>
                                                        </div>
                                                        <ul className='ap-guide-bullets'>
                                                            <li>Your rating could be removed if you use profanity or derogatory terms.</li>
                                                            <li>Don't claim that the insurance company shows bias or favoritism for or against clients.</li>
                                                        </ul>
                                                    </div>
                                                    <TextareaAutosize className='ap-rating-review mt-20' onChange={({ target }) => setReviewText(target.value)} minRows={8} placeholder="What do you want other clients to know about this insurance company?" />
                                                    <div className='column center'>
                                                        <p className='form-agreement text-center ap-terms'>By clicking the "Submit" button, I acknowledge that I have read and agreed to the {infosetting.title} Site Guidelines, Terms of Use and Privacy Policy. Submitted data becomes the property of {infosetting.title}.</p>
                                                        <button type="submit" class="btn btn-block btn-sm mt-5" disabled={isLoading}>
                                                            {isLoading ? "Please Wait..." : "Submit Rating"}
                                                        </button>
                                                    </div>

                                                </form>
                                            </div>
                                        :
                                        <div className='row center height60'>
                                            <APILoader />
                                        </div>
                                    :
                                    <JoinRMPFamily infosetting={infosetting} redirect={`/company/${params.id}`} />
                                }
                            </div>
                        </div>
                    </div>
                </div>

                {ratings && ratings.length > 0 && <div div className='ap-ratings'>
                    <h3>{ratings.length} Rating{ratings.length > 1 ? 's' : ''}</h3>
                    <hr className='hr2' />
                    <div className='adjusters-container'>
                        {ratings.map((rating, index) => <div className='company-main-item'>
                            <div className='adjuster-item row p-rating-item'>
                                <div className='a-rating-container column'>
                                    <span className='a-rating-heading'>Rating</span>
                                    <div className='a-rating-box row center' style={{ backgroundColor: getColor(rating.rating) }}>
                                        <span className='a-rating'>
                                            {rating.rating}{rating.rating.toString().length === 1 ? '.0' : ''}
                                        </span>
                                    </div>
                                </div>
                                <div className='a-name-container column p-rating-item-name'>
                                    <span className='a-name'>{rating.userObj.name}</span>
                                    <span className='ap-review-text'>{rating.review}</span>
                                </div>
                                <div className='a-state-container a-state-container-company column'>
                                    <span className='a-state-name'>{new Date(rating.dateTime).toLocaleDateString()}</span>
                                    {replyIndex !== index && user && <button className='btn btn-primary btn-sm btn-response btn-reply-mobile' onClick={() => setReplyIndex(index)}>Reply</button>}
                                </div>
                            </div>
                            <div className='a-response-container column'>
                                <div className='a-response-content'>
                                    {rating.replies && rating.replies.length ?
                                        rating.replies.map((reply, rIndex) => <ReplyContainer ratingUser={rating.userObj} reply={reply} index={index} rIndex={rIndex} isMyReply={user && rating.user === user._id ? true : false} />)
                                        : null
                                    }
                                    {replyIndex === index && <form className='a-reply-input-container' onSubmit={(e) => onReplySubmit(e, index)}>
                                        <Input placeholder='Add a reply...' className='a-reply-input' name='reply' value={replyText} onChange={(e) => setReplyText(e.target.value)} />
                                        <div className='a-reply-input-button row mt-20 mb-20'>
                                            <button type="button" className='btn btn-white btn-sm btn-response' onClick={() => setReplyIndex(-1)}>Cancel</button>
                                            <button type='submit' className='btn btn-primary btn-sm btn-response ml-10' >Reply</button>
                                        </div>
                                    </form>}
                                </div>
                            </div>
                        </div>
                        )}
                    </div>
                </div>}
            </div> :
                <div className='row center height60'>
                    <APILoader />
                </div>
            }

        </div >
    );
};


function mapStateToProps(state) {
    return { ...state.authentication, ...state };
}

export default connect(mapStateToProps)(Company);